import React from "react";
import Layout from "../../components/Layout";
import FoundationPage from "../../components/FoundationPage";
import { Divider } from "../../components/Divider";

export default function ColorsPage({ location }) {
  return (
    <Layout location={location}>
      <FoundationPage
        pageTitle="Colors"
        description="Color is an essential aspect of design. It’s used to communicate importance and status to the user."
        link1title="View in Figma"
        link1url="https://www.figma.com/file/08RyCY34fSWYCxwrFWbojr/Global-Design-System-(Beta)?node-id=3%3A49"
        link2title="View MAF Storybook"
        link2url="https://webcomponents.maf.nielsenmedia.com/gds/?path=/docs/getting-started-design-tokens--page"
        tocItems={["Overview", "Alternate Backgrounds"]}
      >
        <div className="section" id="overview">
          <h2>Overview</h2>
          <p>
            The 2021 rebrand brought a new color palette to Nielsen. It’s a more
            vivid and rich set of colors than our previous branding. The palette
            is both flexible and inclusive. It encompasses a broad spectrum that
            will be cohesive across all our applications.
          </p>
          <p>
            Because of our emphasis on accessibility, the GDS team worked with
            the Marketing team to expand Nielsen’s brand colors to the 11-scale
            interface colors we have in GDS. These colors fit the needs of UX
            designers and product development. The tweaks allow for proper text
            color contrast, better visual hierarchy, and complex color theming
            possibilities
          </p>
          <p>
            Across a palette, colors can reflect wildly different luminances.
            This creates issues in achieving proper contrast ratios for text
            (4.5:1) and UI (3:1) elements. Common colors like orange, yellow,
            and aqua usually have low luminance and contrast ratios on white
            text. Because of this, we’ve worked to match the luminance at each
            step of the scale. For example, we went through several iterations
            to ensure that gray-100 and blurple-100 would work well with each
            other since they would commonly be used next to each other.
          </p>
          <p>
            All of our components are built to work in both light and dark mode.
            To do this, we optimized the colors at the lighter and darker ends
            of the scale. Those shades at the top and bottom give us excellent
            contrast and differentiation for our UI elements. When we create the
            dark mode of a component, we invert the color scale and tweak as
            needed.{" "}
          </p>
          <p>
            GDS has nine colors: gray, blurple, aqua, eggplant, blue, green,
            red, orange, and yellow. Each color has eleven steps. Including the
            colors white and black gives us a total of 101 interface colors. For
            data visualizations the interface colors should be used most of the
            time. Some data visualizations require a sequential or divergent
            color palette to best show changes in data. We have plans to create
            these, but they are still in progress.
          </p>
          <div
            className="styled-grid mt-space-800"
            style={{ gridTemplateColumns: "repeat(5, 1fr" }}
          >
            <div className="styled-grid gap-space-0 grays">
              <h4>Neutral</h4>
              <div
                className="color-tile"
                style={{ backgroundColor: "var(--gds-white)" }}
              >
                <span>white</span>
              </div>
              <div
                className="color-tile"
                style={{ backgroundColor: "var(--gds-gray-0)" }}
              >
                <span>gray-0</span>
              </div>
              <div
                className="color-tile"
                style={{ backgroundColor: "var(--gds-gray-100)" }}
              >
                <span>gray-100</span>
              </div>
              <div
                className="color-tile"
                style={{ backgroundColor: "var(--gds-gray-200)" }}
              >
                <span>gray-200</span>
              </div>
              <div
                className="color-tile"
                style={{ backgroundColor: "var(--gds-gray-300)" }}
              >
                <span>gray-300</span>{" "}
              </div>
              <div
                className="color-tile"
                style={{ backgroundColor: "var(--gds-gray-400)" }}
              >
                <span>gray-400</span>{" "}
              </div>
              <div
                className="color-tile"
                style={{ backgroundColor: "var(--gds-gray-500)" }}
              >
                <span>gray-500</span>{" "}
              </div>
              <div
                className="color-tile"
                style={{ backgroundColor: "var(--gds-gray-600)" }}
              >
                <span style={{ color: "var(--gds-white)" }}>gray-600</span>{" "}
              </div>
              <div
                className="color-tile"
                style={{ backgroundColor: "var(--gds-gray-700)" }}
              >
                <span style={{ color: "var(--gds-white)" }}>gray-700</span>{" "}
              </div>
              <div
                className="color-tile"
                style={{ backgroundColor: "var(--gds-gray-800)" }}
              >
                <span style={{ color: "var(--gds-white)" }}>gray-800</span>{" "}
              </div>
              <div
                className="color-tile"
                style={{ backgroundColor: "var(--gds-gray-900)" }}
              >
                <span style={{ color: "var(--gds-white)" }}>gray-900</span>{" "}
              </div>
              <div
                className="color-tile"
                style={{ backgroundColor: "var(--gds-gray-1000)" }}
              >
                <span style={{ color: "var(--gds-white)" }}>gray-1000</span>{" "}
              </div>
              <div
                className="color-tile"
                style={{ backgroundColor: "var(--gds-black)" }}
              >
                <span style={{ color: "var(--gds-white)" }}>black</span>{" "}
              </div>
            </div>
            <div className="styled-grid gap-space-0 blurples">
              <h4>Primary</h4>
              <div
                className="color-tile"
                style={{ backgroundColor: "var(--gds-white)" }}
              >
                <span>white</span>
              </div>
              <div
                className="color-tile"
                style={{ backgroundColor: "var(--gds-blurple-0)" }}
              >
                <span>blurple-0</span>
              </div>
              <div
                className="color-tile"
                style={{ backgroundColor: "var(--gds-blurple-100)" }}
              >
                <span>blurple-100</span>
              </div>
              <div
                className="color-tile"
                style={{ backgroundColor: "var(--gds-blurple-200)" }}
              >
                <span>blurple-200</span>
              </div>
              <div
                className="color-tile"
                style={{ backgroundColor: "var(--gds-blurple-300)" }}
              >
                <span>blurple-300</span>{" "}
              </div>
              <div
                className="color-tile"
                style={{ backgroundColor: "var(--gds-blurple-400)" }}
              >
                <span>blurple-400</span>{" "}
              </div>
              <div
                className="color-tile"
                style={{ backgroundColor: "var(--gds-blurple-500)" }}
              >
                <span style={{ color: "var(--gds-white)" }}>blurple-500</span>{" "}
              </div>
              <div
                className="color-tile"
                style={{ backgroundColor: "var(--gds-blurple-600)" }}
              >
                <span style={{ color: "var(--gds-white)" }}>blurple-600</span>{" "}
              </div>
              <div
                className="color-tile"
                style={{ backgroundColor: "var(--gds-blurple-700)" }}
              >
                <span style={{ color: "var(--gds-white)" }}>blurple-700</span>{" "}
              </div>
              <div
                className="color-tile"
                style={{ backgroundColor: "var(--gds-blurple-800)" }}
              >
                <span style={{ color: "var(--gds-white)" }}>blurple-800</span>{" "}
              </div>
              <div
                className="color-tile"
                style={{ backgroundColor: "var(--gds-blurple-900)" }}
              >
                <span style={{ color: "var(--gds-white)" }}>blurple-900</span>{" "}
              </div>
              <div
                className="color-tile"
                style={{ backgroundColor: "var(--gds-blurple-1000)" }}
              >
                <span style={{ color: "var(--gds-white)" }}>blurple-1000</span>{" "}
              </div>
              <div
                className="color-tile"
                style={{ backgroundColor: "var(--gds-black)" }}
              >
                <span style={{ color: "var(--gds-white)" }}>black</span>{" "}
              </div>
            </div>
            <div className="styled-grid gap-space-0 aquas">
              <h4>Secondary</h4>
              <div
                className="color-tile"
                style={{ backgroundColor: "var(--gds-white)" }}
              >
                <span>white</span>
              </div>
              <div
                className="color-tile"
                style={{ backgroundColor: "var(--gds-aqua-0)" }}
              >
                <span>aqua-0</span>
              </div>
              <div
                className="color-tile"
                style={{ backgroundColor: "var(--gds-aqua-100)" }}
              >
                <span>aqua-100</span>
              </div>
              <div
                className="color-tile"
                style={{ backgroundColor: "var(--gds-aqua-200)" }}
              >
                <span>aqua-200</span>
              </div>
              <div
                className="color-tile"
                style={{ backgroundColor: "var(--gds-aqua-300)" }}
              >
                <span>aqua-300</span>{" "}
              </div>
              <div
                className="color-tile"
                style={{ backgroundColor: "var(--gds-aqua-400)" }}
              >
                <span>aqua-400</span>{" "}
              </div>
              <div
                className="color-tile"
                style={{ backgroundColor: "var(--gds-aqua-500)" }}
              >
                <span>aqua-500</span>{" "}
              </div>
              <div
                className="color-tile"
                style={{ backgroundColor: "var(--gds-aqua-600)" }}
              >
                <span>aqua-600</span>{" "}
              </div>
              <div
                className="color-tile"
                style={{ backgroundColor: "var(--gds-aqua-700)" }}
              >
                <span style={{ color: "var(--gds-white)" }}>aqua-700</span>{" "}
              </div>
              <div
                className="color-tile"
                style={{ backgroundColor: "var(--gds-aqua-800)" }}
              >
                <span style={{ color: "var(--gds-white)" }}>aqua-800</span>{" "}
              </div>
              <div
                className="color-tile"
                style={{ backgroundColor: "var(--gds-aqua-900)" }}
              >
                <span style={{ color: "var(--gds-white)" }}>aqua-900</span>{" "}
              </div>
              <div
                className="color-tile"
                style={{ backgroundColor: "var(--gds-aqua-1000)" }}
              >
                <span style={{ color: "var(--gds-white)" }}>aqua-1000</span>{" "}
              </div>
              <div
                className="color-tile"
                style={{ backgroundColor: "var(--gds-black)" }}
              >
                <span style={{ color: "var(--gds-white)" }}>black</span>{" "}
              </div>
            </div>
            <div className="styled-grid gap-space-0 eggplants">
              <h4>Tertiary</h4>
              <div
                className="color-tile"
                style={{ backgroundColor: "var(--gds-white)" }}
              >
                <span>white</span>
              </div>
              <div
                className="color-tile"
                style={{ backgroundColor: "var(--gds-eggplant-0)" }}
              >
                <span>eggplant-0</span>
              </div>
              <div
                className="color-tile"
                style={{ backgroundColor: "var(--gds-eggplant-100)" }}
              >
                <span>eggplant-100</span>
              </div>
              <div
                className="color-tile"
                style={{ backgroundColor: "var(--gds-eggplant-200)" }}
              >
                <span>eggplant-200</span>
              </div>
              <div
                className="color-tile"
                style={{ backgroundColor: "var(--gds-eggplant-300)" }}
              >
                <span>eggplant-300</span>{" "}
              </div>
              <div
                className="color-tile"
                style={{ backgroundColor: "var(--gds-eggplant-400)" }}
              >
                <span>eggplant-400</span>{" "}
              </div>
              <div
                className="color-tile"
                style={{ backgroundColor: "var(--gds-eggplant-500)" }}
              >
                <span style={{ color: "var(--gds-white)" }}>eggplant-500</span>{" "}
              </div>
              <div
                className="color-tile"
                style={{ backgroundColor: "var(--gds-eggplant-600)" }}
              >
                <span style={{ color: "var(--gds-white)" }}>eggplant-600</span>{" "}
              </div>
              <div
                className="color-tile"
                style={{ backgroundColor: "var(--gds-eggplant-700)" }}
              >
                <span style={{ color: "var(--gds-white)" }}>eggplant-700</span>{" "}
              </div>
              <div
                className="color-tile"
                style={{ backgroundColor: "var(--gds-eggplant-800)" }}
              >
                <span style={{ color: "var(--gds-white)" }}>eggplant-800</span>{" "}
              </div>
              <div
                className="color-tile"
                style={{ backgroundColor: "var(--gds-eggplant-900)" }}
              >
                <span style={{ color: "var(--gds-white)" }}>eggplant-900</span>{" "}
              </div>
              <div
                className="color-tile"
                style={{ backgroundColor: "var(--gds-eggplant-1000)" }}
              >
                <span style={{ color: "var(--gds-white)" }}>eggplant-1000</span>{" "}
              </div>
              <div
                className="color-tile"
                style={{ backgroundColor: "var(--gds-black)" }}
              >
                <span style={{ color: "var(--gds-white)" }}>black</span>{" "}
              </div>
            </div>
            <div className="styled-grid gap-space-0 blues">
              <h4>Info</h4>
              <div
                className="color-tile"
                style={{ backgroundColor: "var(--gds-white)" }}
              >
                <span>white</span>
              </div>
              <div
                className="color-tile"
                style={{ backgroundColor: "var(--gds-blue-0)" }}
              >
                <span>blue-0</span>
              </div>
              <div
                className="color-tile"
                style={{ backgroundColor: "var(--gds-blue-100)" }}
              >
                <span>blue-100</span>
              </div>
              <div
                className="color-tile"
                style={{ backgroundColor: "var(--gds-blue-200)" }}
              >
                <span>blue-200</span>
              </div>
              <div
                className="color-tile"
                style={{ backgroundColor: "var(--gds-blue-300)" }}
              >
                <span>blue-300</span>{" "}
              </div>
              <div
                className="color-tile"
                style={{ backgroundColor: "var(--gds-blue-400)" }}
              >
                <span>blue-400</span>{" "}
              </div>
              <div
                className="color-tile"
                style={{ backgroundColor: "var(--gds-blue-500)" }}
              >
                <span>blue-500</span>{" "}
              </div>
              <div
                className="color-tile"
                style={{ backgroundColor: "var(--gds-blue-600)" }}
              >
                <span>blue-600</span>{" "}
              </div>
              <div
                className="color-tile"
                style={{ backgroundColor: "var(--gds-blue-700)" }}
              >
                <span style={{ color: "var(--gds-white)" }}>blue-700</span>{" "}
              </div>
              <div
                className="color-tile"
                style={{ backgroundColor: "var(--gds-blue-800)" }}
              >
                <span style={{ color: "var(--gds-white)" }}>blue-800</span>{" "}
              </div>
              <div
                className="color-tile"
                style={{ backgroundColor: "var(--gds-blue-900)" }}
              >
                <span style={{ color: "var(--gds-white)" }}>blue-900</span>{" "}
              </div>
              <div
                className="color-tile"
                style={{ backgroundColor: "var(--gds-blue-1000)" }}
              >
                <span style={{ color: "var(--gds-white)" }}>blue-1000</span>{" "}
              </div>
              <div
                className="color-tile"
                style={{ backgroundColor: "var(--gds-black)" }}
              >
                <span style={{ color: "var(--gds-white)" }}>black</span>{" "}
              </div>
            </div>
          </div>
          <div className="styled-grid mt-space-800">
            <div
              className="styled-grid"
              style={{ gridTemplateColumns: "repeat(4,1fr)" }}
            >
              <div className="styled-grid gap-space-0 greens">
                <h4>Success</h4>
                <div
                  className="color-tile"
                  style={{ backgroundColor: "var(--gds-white)" }}
                >
                  <span>white</span>
                </div>
                <div
                  className="color-tile"
                  style={{ backgroundColor: "var(--gds-green-0)" }}
                >
                  <span>green-0</span>
                </div>
                <div
                  className="color-tile"
                  style={{ backgroundColor: "var(--gds-green-100)" }}
                >
                  <span>green-100</span>
                </div>
                <div
                  className="color-tile"
                  style={{ backgroundColor: "var(--gds-green-200)" }}
                >
                  <span>green-200</span>
                </div>
                <div
                  className="color-tile"
                  style={{ backgroundColor: "var(--gds-green-300)" }}
                >
                  <span>green-300</span>{" "}
                </div>
                <div
                  className="color-tile"
                  style={{ backgroundColor: "var(--gds-green-400)" }}
                >
                  <span>green-400</span>{" "}
                </div>
                <div
                  className="color-tile"
                  style={{ backgroundColor: "var(--gds-green-500)" }}
                >
                  <span>green-500</span>{" "}
                </div>
                <div
                  className="color-tile"
                  style={{ backgroundColor: "var(--gds-green-600)" }}
                >
                  <span>green-600</span>{" "}
                </div>
                <div
                  className="color-tile"
                  style={{ backgroundColor: "var(--gds-green-700)" }}
                >
                  <span style={{ color: "var(--gds-white)" }}>green-700</span>{" "}
                </div>
                <div
                  className="color-tile"
                  style={{ backgroundColor: "var(--gds-green-800)" }}
                >
                  <span style={{ color: "var(--gds-white)" }}>green-800</span>{" "}
                </div>
                <div
                  className="color-tile"
                  style={{ backgroundColor: "var(--gds-green-900)" }}
                >
                  <span style={{ color: "var(--gds-white)" }}>green-900</span>{" "}
                </div>
                <div
                  className="color-tile"
                  style={{ backgroundColor: "var(--gds-green-1000)" }}
                >
                  <span style={{ color: "var(--gds-white)" }}>green-1000</span>{" "}
                </div>
                <div
                  className="color-tile"
                  style={{ backgroundColor: "var(--gds-black)" }}
                >
                  <span style={{ color: "var(--gds-white)" }}>black</span>{" "}
                </div>
              </div>
              <div className="styled-grid gap-space-0 reds">
                <h4>Danger</h4>
                <div
                  className="color-tile"
                  style={{ backgroundColor: "var(--gds-white)" }}
                >
                  <span>white</span>
                </div>
                <div
                  className="color-tile"
                  style={{ backgroundColor: "var(--gds-red-0)" }}
                >
                  <span>red-0</span>
                </div>
                <div
                  className="color-tile"
                  style={{ backgroundColor: "var(--gds-red-100)" }}
                >
                  <span>red-100</span>
                </div>
                <div
                  className="color-tile"
                  style={{ backgroundColor: "var(--gds-red-200)" }}
                >
                  <span>red-200</span>
                </div>
                <div
                  className="color-tile"
                  style={{ backgroundColor: "var(--gds-red-300)" }}
                >
                  <span>red-300</span>{" "}
                </div>
                <div
                  className="color-tile"
                  style={{ backgroundColor: "var(--gds-red-400)" }}
                >
                  <span>red-400</span>{" "}
                </div>
                <div
                  className="color-tile"
                  style={{ backgroundColor: "var(--gds-red-500)" }}
                >
                  <span style={{ color: "var(--gds-white)" }}>red-500</span>{" "}
                </div>
                <div
                  className="color-tile"
                  style={{ backgroundColor: "var(--gds-red-600)" }}
                >
                  <span style={{ color: "var(--gds-white)" }}>red-600</span>{" "}
                </div>
                <div
                  className="color-tile"
                  style={{ backgroundColor: "var(--gds-red-700)" }}
                >
                  <span style={{ color: "var(--gds-white)" }}>red-700</span>{" "}
                </div>
                <div
                  className="color-tile"
                  style={{ backgroundColor: "var(--gds-red-800)" }}
                >
                  <span style={{ color: "var(--gds-white)" }}>red-800</span>{" "}
                </div>
                <div
                  className="color-tile"
                  style={{ backgroundColor: "var(--gds-red-900)" }}
                >
                  <span style={{ color: "var(--gds-white)" }}>red-900</span>{" "}
                </div>
                <div
                  className="color-tile"
                  style={{ backgroundColor: "var(--gds-red-1000)" }}
                >
                  <span style={{ color: "var(--gds-white)" }}>red-1000</span>{" "}
                </div>
                <div
                  className="color-tile"
                  style={{ backgroundColor: "var(--gds-black)" }}
                >
                  <span style={{ color: "var(--gds-white)" }}>black</span>{" "}
                </div>
              </div>
              <div className="styled-grid gap-space-0 oranges">
                <h4>Warning</h4>
                <div
                  className="color-tile"
                  style={{ backgroundColor: "var(--gds-white)" }}
                >
                  <span>white</span>
                </div>
                <div
                  className="color-tile"
                  style={{ backgroundColor: "var(--gds-orange-0)" }}
                >
                  <span>orange-0</span>
                </div>
                <div
                  className="color-tile"
                  style={{ backgroundColor: "var(--gds-orange-100)" }}
                >
                  <span>orange-100</span>
                </div>
                <div
                  className="color-tile"
                  style={{ backgroundColor: "var(--gds-orange-200)" }}
                >
                  <span>orange-200</span>
                </div>
                <div
                  className="color-tile"
                  style={{ backgroundColor: "var(--gds-orange-300)" }}
                >
                  <span>orange-300</span>{" "}
                </div>
                <div
                  className="color-tile"
                  style={{ backgroundColor: "var(--gds-orange-400)" }}
                >
                  <span>orange-400</span>{" "}
                </div>
                <div
                  className="color-tile"
                  style={{ backgroundColor: "var(--gds-orange-500)" }}
                >
                  <span style={{ color: "var(--gds-white)" }}>orange-500</span>{" "}
                </div>
                <div
                  className="color-tile"
                  style={{ backgroundColor: "var(--gds-orange-600)" }}
                >
                  <span style={{ color: "var(--gds-white)" }}>orange-600</span>{" "}
                </div>
                <div
                  className="color-tile"
                  style={{ backgroundColor: "var(--gds-orange-700)" }}
                >
                  <span style={{ color: "var(--gds-white)" }}>orange-700</span>{" "}
                </div>
                <div
                  className="color-tile"
                  style={{ backgroundColor: "var(--gds-orange-800)" }}
                >
                  <span style={{ color: "var(--gds-white)" }}>orange-800</span>{" "}
                </div>
                <div
                  className="color-tile"
                  style={{ backgroundColor: "var(--gds-orange-900)" }}
                >
                  <span style={{ color: "var(--gds-white)" }}>orange-900</span>{" "}
                </div>
                <div
                  className="color-tile"
                  style={{ backgroundColor: "var(--gds-orange-1000)" }}
                >
                  <span style={{ color: "var(--gds-white)" }}>orange-1000</span>{" "}
                </div>
                <div
                  className="color-tile"
                  style={{ backgroundColor: "var(--gds-black)" }}
                >
                  <span style={{ color: "var(--gds-white)" }}>black</span>{" "}
                </div>
              </div>
              <div className="styled-grid gap-space-0 yellows">
                <h4>Highlight</h4>
                <div
                  className="color-tile"
                  style={{ backgroundColor: "var(--gds-white)" }}
                >
                  <span>white</span>
                </div>
                <div
                  className="color-tile"
                  style={{ backgroundColor: "var(--gds-yellow-0)" }}
                >
                  <span>yellow-0</span>
                </div>
                <div
                  className="color-tile"
                  style={{ backgroundColor: "var(--gds-yellow-100)" }}
                >
                  <span>yellow-100</span>
                </div>
                <div
                  className="color-tile"
                  style={{ backgroundColor: "var(--gds-yellow-200)" }}
                >
                  <span>yellow-200</span>
                </div>
                <div
                  className="color-tile"
                  style={{ backgroundColor: "var(--gds-yellow-300)" }}
                >
                  <span>yellow-300</span>{" "}
                </div>
                <div
                  className="color-tile"
                  style={{ backgroundColor: "var(--gds-yellow-400)" }}
                >
                  <span>yellow-400</span>{" "}
                </div>
                <div
                  className="color-tile"
                  style={{ backgroundColor: "var(--gds-yellow-500)" }}
                >
                  <span style={{ color: "var(--gds-white)" }}>yellow-500</span>{" "}
                </div>
                <div
                  className="color-tile"
                  style={{ backgroundColor: "var(--gds-yellow-600)" }}
                >
                  <span style={{ color: "var(--gds-white)" }}>yellow-600</span>{" "}
                </div>
                <div
                  className="color-tile"
                  style={{ backgroundColor: "var(--gds-yellow-700)" }}
                >
                  <span style={{ color: "var(--gds-white)" }}>yellow-700</span>{" "}
                </div>
                <div
                  className="color-tile"
                  style={{ backgroundColor: "var(--gds-yellow-800)" }}
                >
                  <span style={{ color: "var(--gds-white)" }}>yellow-800</span>{" "}
                </div>
                <div
                  className="color-tile"
                  style={{ backgroundColor: "var(--gds-yellow-900)" }}
                >
                  <span style={{ color: "var(--gds-white)" }}>yellow-900</span>{" "}
                </div>
                <div
                  className="color-tile"
                  style={{ backgroundColor: "var(--gds-yellow-1000)" }}
                >
                  <span style={{ color: "var(--gds-white)" }}>yellow-1000</span>{" "}
                </div>
                <div
                  className="color-tile"
                  style={{ backgroundColor: "var(--gds-black)" }}
                >
                  <span style={{ color: "var(--gds-white)" }}>black</span>{" "}
                </div>
              </div>
            </div>
          </div>
        </div>
        <Divider />
        <div className="section" id="alternate-backgrounds">
          <h2>Alternate Backgrounds</h2>
          <p>
            Providing proper contrast is an important principle in GDS. Because
            of this, several components offer an alternate background option.
            This helps to set the element apart from the element’s background
            color.
          </p>
          <p>
            In light mode, backgrounds are white, gray-0, or gray-100. In dark
            mode, backgrounds are either black, gray-1000, or gray-900. Choose
            the proper component variant based on your page’s background.
          </p>
          <p2>Components with alternate backgrounds:</p2>
          <ul>
            <li>Input</li>
            <li>Select</li>
            <li>Tile</li>
            <li>Radio Tile</li>
            <li>Checkbox Tile</li>
          </ul>
        </div>
        <Divider />
      </FoundationPage>
    </Layout>
  );
}
